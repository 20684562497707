/* eslint-disable max-len */
import { formatDateOut, formatDate } from 'utils';

export default {
    source: 'SC Tray',
    sourceType: 'contact',
    title: 'SC Tray Contacts',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            filter: 'firstName',
            required: true,
            width: 120,
        },
        {
            label: 'Date of Birth',
            name: 'dateOfBirth',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            filter: 'dateOfBirth',
            width: 90
        },
        {
            label: 'Employee Id',
            autofill: {
                name: 'claimant_employee_id'
            },
            name: 'employeeId',
            filter: 'employeeId',
            width: 80
        },
        {
            label: 'Occupation',
            name: 'occupation',
            autofill: {
                name: 'employment_job_title_or_occupation',
            },
            width: 120
        },
        {
            label: 'Hire Date',
            name: 'dateOfHire',
            autofill: {
                name: 'employment_hired_date',
                transform: formatDateOut
            },
            filter: 'hireDate',
            width: 70
        },
        {
            label: 'Email',
            name: 'email',
            autofill: {
                name: 'claimant_email_address'
            },
        },
        {
            label: 'Home Phone',
            name: 'homePhone',
            autofill: {
                name: 'claimant_home_phone'
            },
            width: 150
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                transform: value => (
                    {
                        M: '1',
                        F: '2'
                    }[value] || undefined)
            },
            width: 70,
        },
        {
            label: 'Marital Status',
            name: 'maritalStatus',
            autofill: {
                name: 'claimant_marital_status_code',
                transform: value => (
                    {
                        Single: '2',
                        Married: '4',
                        Divorced: '3',
                        Separated: '5',
                        Widowed: '6',
                        Unknown: '7',
                        'Domestic Partner': '8',
                    }[value] || undefined)
            },
            width: 80
        },
        {
            label: 'Address',
            name: 'address1',
            autofill: {
                name: 'claimant_address'
            },
            width: 220
        },
        {
            label: 'Address 2',
            name: 'address2',
            autofill: {
                name: 'claimant_address2'
            },
            width: 220
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 100
        },
        {
            label: 'State',
            autofill: {
                name: 'claimant_state'
            },
            name: 'state',
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
    ]
};
