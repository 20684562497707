/* eslint-disable max-len */
import { formatSsn, formatDate, formatDateOut } from 'utils';
import { USStateOptions } from 'config';

export default {
    source: 'GB API',
    sourceType: 'contact',
    title: 'Gallagher Bassett',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    transform: data => data.sortByString('firstName'),
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            name: 'lastName',
            filter: 'lastName',
            required: true,
            autofill: {
                name: 'claimant_last_name'
            },
            width: 120,
        },
        {
            label: 'First Name',
            name: 'firstName',
            filter: 'firstName',
            autofill: {
                name: 'claimant_first_name'
            },
            width: 120,
        },
        {
            label: 'SSN',
            name: 'ssn',
            filterType: 'ssn',
            filter: 'ssn',
            render: formatSsn,
            autofill: {
                name: 'claimant_ssn'
            },
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            filter: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            name: 'employeeId',
            filter: 'employeeId',
            autofill: {
                name: 'claimant_employee_id'
            },
            width: 100
        },
        {
            label: 'Email',
            name: 'email',
            autofill: {
                name: 'claimant_email_address'
            },
            width: 120
        },
        {
            label: 'Home Phone',
            name: 'homePhone',
            autofill: {
                name: 'claimant_mobile_phone',
                editable: true
            },
            width: 150
        },
        {
            label: 'Work Phone',
            name: 'workPhone',
            autofill: {
                name: 'claimant_work_phone'
            },
            width: 150
        },
        {
            label: 'Gender',
            name: 'gender',
            render: value => ({ 1: 'M', 2: 'F' }[value] || ''),
            autofill: {
                name: 'claimant_gender_code',
                transform: value => (value || '').toString(),
            },
            width: 60
        },
        {
            label: 'Address',
            name: 'address1',
            autofill: {
                name: 'claimant_address'
            },
            width: 120
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 100
        },
        {
            label: 'State',
            name: 'state',
            options: USStateOptions,
            autofill: {
                name: 'claimant_state'
            },
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
        {
            label: 'Location',
            name: 'location_name',
            autofill: {
                name: 'employer_location_name',
                ignoreEmpty: true
            },
            width: 150,
        },
        {
            label: 'Location Ref. Number',
            name: 'location_referenceNumber',
            autofill: {
                name: 'employer_location_number',
                ignoreEmpty: true
            },
            width: 70,
        },
        {
            label: 'Location Address',
            name: 'location_address1',
            autofill: {
                name: 'employer_location_address',
                ignoreEmpty: true
            },
            width: 150,
        },
        {
            label: 'Location Address 2',
            name: 'location_address2',
            autofill: {
                name: 'employer_location_address2',
                ignoreEmpty: true
            },
        },
        {
            label: 'Location City',
            name: 'location_city',
            autofill: {
                name: 'employer_location_city',
                ignoreEmpty: true
            },
            width: 120,
        },
        {
            label: 'Location State',
            name: 'location_state',
            render: value => value.substr(0, 2),
            autofill: {
                name: 'employer_location_state',
                ignoreEmpty: true,
                transform: value => value.substr(0, 2),
            },
        },
        {
            label: 'Location Zip',
            name: 'location_postalCode',
            autofill: {
                name: 'employer_location_zip',
                ignoreEmpty: true
            },
        },
    ]
};
