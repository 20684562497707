import React, { useCallback, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Drawer, Result, Form } from 'library';
import { EsIncidentsService } from 'services';
import { PlusCircleFilled } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { showError } from 'utils';
import IncidentContext from 'routes/Incident/context';
import { IncidentCard } from '../IncidentCard';
import { IncidentProps } from './config';

const CreateIncident = ({
    disabled = false,
    incidentTypeId,
    fieldName, fieldValue,
}) => {
    const { update } = useContext(IncidentContext);
    const { nextId } = useParams();
    const [related, setRelated] = useState();
    const {
        id: incidentId,
        relatedIncident,
        incidentTypeId: thisIncidentTypeId,
        $incidentType,
        ...rest
    } = useSelector(state => state.incident);
    const navigate = useNavigate();
    const fieldToWatch = ['fields', fieldName];
    const form = Form.useFormInstance();
    const watchedValue = Form.useWatch(fieldToWatch, form)?.value;
    const defaultWatchedValue = (rest.fields || {})[fieldName]?.value;
    const touched = form.isFieldsTouched(fieldToWatch);
    const { id: relatedIncidentId } = relatedIncident || {};

    const createRealatedIncident = useCallback(() => {
        EsIncidentsService.clone(incidentId, {
            incidentTypeId,
            syncToId: incidentId,
        })
            .then((data) => {
                setRelated(data);
                navigate(`/incidents/${incidentId}/intake/${data.id}`);
                return data.id;
            })
            .then((syncToId) => {
                const values = form.getFieldsValue();
                update({ ...values, syncToId });
            })
            .catch(showError);
    }, [incidentId, navigate, incidentTypeId, update, form]);

    const needsToCreateRelatedIncident = !disabled
        && !nextId
        && !relatedIncidentId
        && touched
        && watchedValue === fieldValue
        && defaultWatchedValue !== watchedValue;

    useEffect(() => {
        if (needsToCreateRelatedIncident) {
            createRealatedIncident();
        }
    }, [
        createRealatedIncident,
        needsToCreateRelatedIncident
    ]);

    if (!related || disabled) {
        return null;
    }

    return (
        <Drawer
            title={(
                <Result
                    icon={<PlusCircleFilled />}
                    status="success"
                    title={`${related.$incidentType.abbr} Incident was just created`}
                    subTitle={`A new ${related.$incidentType.title} incident will be opened after completing the current ${$incidentType.abbr} intake. Submit it to be brought back to the current incident.`}
                />
            )}
            open={!!related}
            closable={false}
            footer={(
                <Button
                    type="primary"
                    block
                    onClick={() => setRelated()}
                >
                    Close
                </Button>
            )}
        >
            <IncidentCard
                incident={related}
            >
                {IncidentProps
                    .map(({ label, key, render }) => {
                        let value = related[key];
                        value = render ? render(related) : value;
                        return (
                            <React.Fragment key={key}>
                                <div> {label}</div>
                                <span>{value}</span>
                            </React.Fragment>
                        );
                    })}
            </IncidentCard>
        </Drawer>
    );
};

CreateIncident.propTypes = {
    disabled: PropTypes.bool,
    incidentTypeId: PropTypes.number.isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.string.isRequired,
};

export default CreateIncident;
