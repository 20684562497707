import './DistanceFilter.scss';
import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Space, Button, Spin, Tag, Input } from 'library';
import { SearchOutlined } from '@ant-design/icons';
import { IntakeDropdown } from 'components/IntakeDropdown';
import FacilityContext from '../../context';

const DistanceFilter = () => {
    const { setAddress } = useContext(FacilityContext);
    const { fields, $loading } = useSelector(state => state.incident);
    const [value, setValue] = useState();
    const [valueType, setValueType] = useState();

    const onSearchClick = () => {
        setAddress(value);
    };

    const getAddressValue = names => (fields ? names
        .map(name => fields[name]?.value?.toString())
        .filter(val => !!val)
        .join(', ') : undefined);

    return (
        <Spin spinning={!!$loading}>
            <div className="distance-filter-label">
                Measure Facility Distance From:
                {' '}
                {valueType && <Tag>{valueType}</Tag>}
            </div>

            <Space.Compact block className="distance-filter">
                <IntakeDropdown
                    value={value}
                    onChange={(setValue)}
                    onSelect={(item) => {
                        if (item) {
                            const { value: val, label } = item;
                            setValue(val);
                            setValueType(label);
                            setAddress(val);
                        }
                    }}
                    options={[
                        {
                            label: 'Incident Address',
                            value: getAddressValue(['injury_address', 'injury_city']),
                        },
                        {
                            label: 'Employer Location Address',
                            value: getAddressValue(['employer_location_address', 'employer_location_city']),
                        },
                        {
                            label: 'Claimant Address',
                            value: getAddressValue(['claimant_address', 'claimant_city']),
                        },
                    ]}
                >
                    <Input
                        placeholder="Required"
                        onKeyDown={({ keyCode }) => (keyCode === 13 ? onSearchClick() : null)}
                    />
                </IntakeDropdown>
                <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    onClick={onSearchClick}
                    disabled={!value}
                />
            </Space.Compact>
        </Spin>
    );
};

export { DistanceFilter };
