import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'library';
import { ButtonCallActions } from 'components/buttons';
import { ArrowLeftOutlined } from '@ant-design/icons';

const NoTriageSummaryFooter = () => {
    const form = Form.useFormInstance();
    const navigate = useNavigate();
    const { $updating, id } = useSelector(state => state.incident);
    
    return (
        <footer>
            <Button
                type="default"
                onClick={() => navigate(`/incidents/${id}/no-triage`)}
                icon={<ArrowLeftOutlined />}
            />
            <ButtonCallActions />
            <Button
                type="primary"
                onClick={form.submit}
                loading={$updating}
            >
                Finish
            </Button>
        </footer>
    );
};

export default NoTriageSummaryFooter;
