import { formatSsn, formatDate, } from 'utils';
import { USStateOptions } from 'config';

export default {
    source: 'Enspiri API',
    sourceType: 'contact',
    title: 'Lintelio',
    label: 'Internal Database Lookup',
    className: 'w1000 info',
    description: 'The chosen location gives you the option to search our database of known claimants to speed up the intake process.',
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            name: 'lastName',
            filter: 'lastName',
            required: true,
            autofill: {
                name: 'claimant_last_name',
                editable: true,
            },
            width: 120,
        },
        {
            label: 'First Name',
            name: 'firstName',
            filter: 'firstName',
            autofill: {
                name: 'claimant_first_name',
                editable: true,
            },
            width: 120,
        },
        {
            label: 'SSN',
            name: 'ssn',
            autofill: {
                name: 'claimant_ssn',
                editable: true,
            },
            filter: 'ssn',
            filterType: 'ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                editable: true,
            },
            render: formatDate,
            filter: 'dob',
            width: 90
        },
        {
            label: 'Employee Id',
            name: 'employeeId',
            autofill: {
                name: 'claimant_employee_id',
                editable: true,
            },
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Occupation',
            name: 'occupation',
            autofill: {
                name: 'employment_job_title_or_occupation',
                editable: true,
            },
            width: 120
        },
        {
            label: 'Hire Date',
            name: 'hireDate',
            autofill: {
                name: 'employment_hired_date',
                editable: true,
            },
            render: formatDate,
            width: 120
        },
        {
            label: 'Email',
            name: 'email',
            autofill: {
                name: 'claimant_email_address',
                editable: true,
            },
            width: 120
        },
        {
            label: 'Home Phone',
            name: 'homePhone',
            autofill: {
                name: 'claimant_home_phone',
                editable: true,
            },
            width: 150
        },
        {
            label: 'Mobile Phone',
            name: 'mobilePhone',
            autofill: {
                name: 'claimant_mobile_phone',
                editable: true,
            },
            width: 150
        },
        {
            label: 'Work Phone',
            name: 'workPhone',
            autofill: {
                name: 'claimant_work_phone',
                editable: true,
            },
            width: 150
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                editable: true,
                transform: (_, row) => row.genderCode?.toString(),
            },
            width: 80
        },
        {
            label: 'Marital Status',
            name: 'maritalStatus',
            autofill: {
                name: 'claimant_marital_status_code',
                editable: true,
                transform: (_, row) => row.maritalStatusCode?.toString(),
            },
            width: 80
        },
        {
            label: 'Address',
            name: 'address1',
            autofill: {
                name: 'claimant_address',
                editable: true,
            },
            width: 120
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city',
                editable: true,
            },
            width: 100
        },
        {
            label: 'State',
            autofill: {
                name: 'claimant_state',
                editable: true,
            },
            name: 'state',
            options: USStateOptions,
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip',
                editable: true,
            },
            width: 70
        },
    ]
};
