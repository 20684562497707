import './Note.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea, Popconfirm } from 'library';
import { useSelector } from 'react-redux';
import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { NoteLibraryDropdown } from './LibraryDropdown';
import NoteInfo from './NoteInfo';

const Note = ({ note, libraryItems = [], onChange, updating, disabled }) => {
    const [position, setPosition] = useState();
    const me = useSelector(state => state.me);
    const userEmail = note.createdBy?.email || '';
    const noteDisabled = note.lockedAt
        || (me.email?.toLowerCase() !== userEmail.toLowerCase())
        || disabled;
    const [loading, setLoading] = useState(false);

    const onContextMenu = (event, element) => {
        setPosition({
            start: element.selectionStart,
            end: element.selectionEnd
        });
    };

    const onLibrarySelect = (libraryText) => {
        const textBefore = note.text.substring(0, position.start);
        const textAfter = note.text.substring(position.end);
        const newText = textBefore + libraryText + textAfter;
        onChange({ ...note, text: newText }, true);
    };

    const onBeforeChange = (text) => {
        onChange({
            ...note,
            text,
            changed: true
        }, !text);
    };

    const onDelete = () => {
        setLoading(true);
        onChange(null, true)
            .finally(() => setLoading(false));
    };

    const onBlur = () => {
        if (note.changed) {
            onChange(note, true);
        }
    };

    const spinning = !!(note.changed && updating) || loading;

    const Icon = spinning ? LoadingOutlined : CloseCircleFilled;

    return (
        <div className={`note ${noteDisabled ? 'note-disabled' : ''}`}>
            <NoteInfo note={note} />
            {!noteDisabled && (
                <Popconfirm
                    placement="left"
                    title="Delete Note?"
                    description="Are you sure you want to delete this Note?"
                    onConfirm={onDelete}
                >
                    <Icon
                        className="btn-delete"
                        title="Delete Note"
                    />
                </Popconfirm>
            )}

            <NoteLibraryDropdown
                items={libraryItems}
                onSelect={onLibrarySelect}
                disabled={noteDisabled}
            >
                <TextArea
                    value={note.text}
                    onContextMenu={onContextMenu}
                    onChange={onBeforeChange}
                    onBlur={onBlur}
                    autoSize={{ minRows: 2 }}
                    allowClear={false}
                    disabled={noteDisabled}
                    readOnly={updating}
                />
            </NoteLibraryDropdown>
        </div>
    );
};

Note.propTypes = {
    libraryItems: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    note: PropTypes.object.isRequired,
    updating: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default Note;
