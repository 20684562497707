/* eslint-disable max-len */
import { formatSsn, formatDate } from 'utils';
import dayjs from 'dayjs';

export default {
    source: 'Randstad',
    sourceType: 'contact',
    title: 'Randstad',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    transform: (data) => {
        // https://objectsystems.atlassian.net/browse/LIN-7059
        data.sort((a, b) => {
            const bigData = '12/12/3000';
            const aEnd = dayjs(a.employmentEnd || bigData);
            const bEnd = dayjs(b.employmentEnd || bigData);

            if (aEnd.isSame(bEnd)) {
                const aStart = dayjs(a.employmentBegin || bigData);
                const bStart = dayjs(b.employmentBegin || bigData);
                return aStart.isBefore(bStart) ? 1 : -1;
            }

            return aEnd.isBefore(bEnd) ? 1 : -1;
        });
        return data;
    },
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            required: true,
            width: 140,
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            filter: 'firstName',
            width: 140,
        },
        {
            label: 'SSN',
            name: 'ssn',
            filterType: 'ssn',
            autofill: {
                name: 'claimant_ssn'
            },
            render: formatSsn,
        },
        {
            label: 'Employment Begin',
            name: 'employmentBegin',
            autofill: {
                name: 'employment_hired_date'
            },
            render: formatDate
        },
        {
            label: 'Employment End',
            name: 'employmentEnd',
            render: formatDate
        },
        {
            label: 'Employee Id',
            name: 'employeeId',
            autofill: {
                name: 'claimant_employee_id'
            },
        },
        {
            label: 'Occupation',
            name: 'jobTitle',
            autofill: {
                name: 'employment_job_title_or_occupation',
            },
            width: 220,
        },
        {
            label: 'Email',
            name: 'email',
            autofill: {
                name: 'claimant_email_address'
            },
        },
        {
            label: 'Address',
            name: 'address',
            autofill: {
                name: 'employer_location_address',
                ignoreEmpty: true
            },
            width: 200,
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'employer_location_city',
                ignoreEmpty: true
            }
        },
        {
            label: 'State',
            name: 'state',
            autofill: {
                name: 'employer_location_state',
                ignoreEmpty: true
            }
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'employer_location_zip',
                ignoreEmpty: true
            }
        },
        {
            label: 'Job Desc',
            name: 'jobDesc',
            autofill: {
                name: 'employment_job_description',
            },
            width: 160,
        },
        {
            label: 'Work Site',
            name: 'workSite',
            autofill: {
                name: 'employer_location_name',
                ignoreEmpty: true
            },
            width: 200,
        },
        {
            label: 'Corporate',
            name: 'corporate',
        },
        {
            label: 'Segment',
            name: 'segment',
            width: 70,
        },
        {
            label: 'Status',
            name: 'status',
            width: 70,
        },
        {
            label: 'Sequence Num',
            name: 'sequenceNum',
            autofill: {
                name: 'sequenceNum'
            },
        },
        {
            label: 'Business Unit (BU) number',
            name: 'rklNodeCode',
            autofill: {
                name: 'randstad_bu_number'
            },
        },
        {
            label: 'Region Name',
            name: 'rklLocation9Name',
            autofill: {
                name: 'randstad_region_name'
            },
        },
        {
            label: 'Line-Of-Business Name',
            name: 'rklLocation4Name',
            autofill: {
                name: 'randstad_line_of_business_name'
            },
        }
    ],
    /*
    onSelect: async (item) => {
        const res = { ...item };
        if (res?.fields) {
            res.fields.employer_location_city = res.fields.claimant_city;
            res.fields.employer_location_state = res.fields.claimant_state;
            res.fields.employer_location_zip = res.fields.claimant_zip;
        } 
        return res;
    } */
};
