import {
    InputPhone, Checkbox, InputText, Select,
    InputDateTime, InputTime, InputZip,
    InputBool, TextArea, Confirm,
} from 'library';
import {
    InputSsnES,
    InputAddressES,
    AutoCompleteES,
    TextAreaInjurySurvey,
    InputDateES
} from 'library/CustomES';
import { QUESTION_TYPE } from './types';

export * from './rules';
export * from './types';

export const INJURY_SURVEY_QUESTIONS = [
    'injury_description',
    'injury_nature_of',
    'injury_object_involved',
    'injury_activity_description',
    'injury_pertinent_medical_history'
];

export const getField = ({
    name, type, options, maxLength, fetch, isDirective
}) => {
    if (name?.endsWith('_zip')) {
        return <InputZip />;
    }

    if (['claimant_address', 'injury_address'].includes(name)) {
        return <InputAddressES name={name} />;
    }

    if (INJURY_SURVEY_QUESTIONS.includes(name)) {
        return <TextAreaInjurySurvey name={name} />;
    }

    if (name?.endsWith('_phone') || name?.endsWith('_fax')) {
        return <InputPhone />;
    }

    if (name?.endsWith('_ssn')) {
        return <InputSsnES />;
    }

    if (fetch && !options?.length) {
        return <AutoCompleteES fetch={fetch} />;
    }

    if ([QUESTION_TYPE.LOOKUP, QUESTION_TYPE.SELECT].includes(type) && Array.isArray(options)) {
        return <Select options={options} />;
    }

    switch (type) {
        case QUESTION_TYPE.YESNO: {
            return (
                <Select
                    options={[
                        { value: isDirective ? 'No' : '1', label: 'No' },
                        { value: isDirective ? 'Yes' : '2', label: 'Yes' }
                    ]}
                />
            );
        }
        case QUESTION_TYPE.ALERT:
            return <Confirm />;
        case QUESTION_TYPE.CHECKBOX:
            return <InputBool />;
        case QUESTION_TYPE.CHECKBOXES:
            return <Checkbox.Group options={options} />;
        case QUESTION_TYPE.DATE:
            return <InputDateES />;
        case QUESTION_TYPE.TIME:
            return <InputTime />;
        case QUESTION_TYPE.DATETIME:
            return <InputDateTime />;
        case QUESTION_TYPE.TEXTAREA: {
            return <TextArea />;
        }
        case QUESTION_TYPE.TEXT:
        default:
            return <InputText maxLength={maxLength || 1000} />;
    }
};
