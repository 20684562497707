import { Select as SelectBase } from 'antd';

const Select = (props) => {
    const filterFn = (input, option) => {
        const target = (`${option?.label || '' }@${ option?.code || ''}`).toLowerCase();
        return target.includes(input.toLowerCase());
    };

    return (
        <SelectBase
            showSearch
            optionFilterProp="children"
            filterOption={filterFn}
            popupMatchSelectWidth
            allowClear
            {...props}
        />
    );
};

export { Select };
