import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { EsQuestionsService, EsDirectivesService, EsNotesService } from 'services';
import { useSelector } from 'react-redux';
import IncidentSummary from 'components/IncidentSummary';
import FormNavigation from 'components/FormNavigation';
import { Grid, SubHeader } from 'layout';
import { useDebounce } from 'hooks';
import { catchError } from 'utils';
import IncidentDone from 'routes/Incident/IncidentDone';
import IncidentContext from '../Incident/context';
import IntakeForm from './Form';
import Footer from './Footer';

const IncidentIntakeRoute = ({ readOnly, children }) => {
    const { update, patch, done } = useContext(IncidentContext);
    const incident = useSelector(state => state.incident);
    const [categories, setCategories] = useState();
    const [directives, setDirectives] = useState([]);
    const [debouncedFields, setDebouncedFields] = useDebounce(undefined, 4000);
    const [questionsLoading, setQuestionsLoading] = useState(false);

    useEffect(() => {
        if (debouncedFields) {
            patch(debouncedFields);
        }
    }, [debouncedFields, patch]);

    const updateForm = (values) => {
        setQuestionsLoading(true);
        update(values)
            .finally(setQuestionsLoading);
    };

    const onValuesChange = (changed, all) => {
        if (changed.episodeTypeId) {
            updateForm({
                episodeTypeId: all.episodeTypeId,
                locationId: all.locationId
            });
        } else if (changed.fields?.employer_location_state) {
            patch(changed);
        } else if (changed.fields || changed.directives) {
            setDebouncedFields({ fields: all.fields, directives: all.directives });
        }
    };

    useEffect(() => {
        if (incident.locationId) {
            setQuestionsLoading(true);
            EsQuestionsService.list({
                id: incident.locationId,
                episodeTypeId: incident.episodeTypeId
            })
                .then(setCategories)
                .then(() => EsDirectivesService.list({
                    locationId: incident.locationId,
                    episodeTypeId: incident.episodeTypeId,
                }))
                .then(setDirectives)
                .catch(catchError)
                .finally(setQuestionsLoading);
        }
    }, [incident.locationId, incident.episodeTypeId]);

    const disabled = incident.$loading || !!questionsLoading;

    return (
        <>
            <SubHeader>
                <FormNavigation
                    categories={[
                        { title: 'General', name: 'general' },
                        ...(categories || [])
                    ]}
                />
            </SubHeader>
            <Grid className="grid-intake">
                {done ? <IncidentDone />
                    : (
                        <IntakeForm
                            onValuesChange={onValuesChange}
                            categories={categories}
                            directives={directives}
                            loading={questionsLoading}
                            readOnly={readOnly}
                        >
                            {children || (
                                <Footer
                                    disabled={disabled}
                                    categories={categories}
                                />
                            )}
                        </IntakeForm>
                    )}
                <IncidentSummary
                    noteType={EsNotesService.INTAKE_TYPE}
                />
            </Grid>
        </>
    );
};

IncidentIntakeRoute.propTypes = {
    readOnly: PropTypes.bool,
    children: PropTypes.any
};

export default IncidentIntakeRoute;
