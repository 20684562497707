import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'library';
import { Rules } from 'utils';
import LocationInput from './LocationInput';

const LookupGBLocation = ({ disabled }) => {
    const [required, setRequired] = useState(true);
    const { usePyramid, ediUnique } = useSelector(state => state.location);

    if (!(usePyramid && ediUnique)) {
        return null;
    }

    return (
        <Form.Item
            name={['thirdParty', 'location']}
            label="Gallagher Bassett Pyramid Location"
            rules={required ? [Rules.required] : null}
            className={`form-item-thirdParty-location ${ required ? 'required' : null}`}
        >
            <LocationInput
                disabled={disabled}
                required={required}
                setRequired={setRequired}
            />
        </Form.Item>
    );
};

LookupGBLocation.propTypes = {
    disabled: PropTypes.bool
};

export { LookupGBLocation };
