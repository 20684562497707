import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Space, Tooltip } from 'antd';
import { getField } from 'config';
import ButtonUnknown from './ButtonUnknown';
import ButtonRefused from './ButtonRefused';
import ButtonEstimated from './ButtonEstimated';

const FormField = ({
    type,
    required = false,
    refused = true,
    unknown = true,
    estimated = false,
    disabled = false,
    value = {},
    onChange,
    onBlur,
    name,
    setAutofilled,
    ...rest
}) => {
    const ElementBase = getField({ type, name, ...rest });
    const autofilled = value?.autofilled;
    const hasAltButtons = (required || estimated) && !autofilled;

    const onElementChange = (e) => {
        let elementValue = e;
        if (e?.bubbles) {
            elementValue = e.target.value;
        }
        onChange({ ...value, value: elementValue });
    };

    useEffect(() => {
        if (autofilled && !disabled) {
            setAutofilled(autofilled);
        }
    }, [autofilled, disabled, setAutofilled]);

    const Element = React.cloneElement(ElementBase, {
        name,
        value: value?.value,
        onChange: onElementChange,
        onBlur,
        disabled: disabled || autofilled || (required && (value?.refused || value?.unknown)),
        placeholder: required ? 'Required' : '',
        required,
    });

    if (!hasAltButtons) {
        return (
            <Tooltip
                destroyTooltipOnHide
                overlayClassName="lx-autofilled"
                title="Employer Verified Info"
                open={autofilled && !disabled}
                trigger={[]}
                placement="right"
                zIndex={3}
                color="green"
            >
                <div>{Element}</div>
            </Tooltip>
        );
    }

    return (
        <Space.Compact
            className="form-item-group"
            block
        >
            {Element}
            {estimated && (
                <ButtonEstimated
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                />
            )}
            {required && refused && (
                <ButtonRefused
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                />

            )}
            {required && unknown && (
                <ButtonUnknown
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                />
            )}
        </Space.Compact>
    );
};

FormField.propTypes = {
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    unknown: PropTypes.bool,
    refused: PropTypes.bool,
    estimated: PropTypes.bool,
    autofilled: PropTypes.bool,
    value: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    setAutofilled: PropTypes.func.isRequired
};

export default FormField;
