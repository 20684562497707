/* eslint-disable max-len */
import { formatSsn, formatDate, formatDateOut } from 'utils';

export default {
    source: 'Rubio HR',
    sourceType: 'contact',
    title: 'Rubio HR',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            filter: 'firstName',
            required: true,
            width: 90
        },
        {
            label: 'SSN',
            name: 'ssn',
            autofill: {
                name: 'claimant_ssn'
            },
            filter: 'claimant_ssn',
            render: formatSsn,
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            autofill: {
                name: 'claimant_employee_id'
            },
            name: 'employeeId',
            filter: 'employeeId',
            width: 100
        },
        {
            label: 'Occupation',
            name: 'jobTitle',
            autofill: {
                name: 'employment_job_title_or_occupation',
            },
            width: 100
        },
        {
            label: 'Hire Date',
            name: 'hireDate',
            autofill: {
                name: 'employment_hired_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                transform: value => (
                    {
                        M: '1',
                        F: '2'
                    }[value] || undefined)
            },
            width: 60
        },
        {
            label: 'Department',
            name: 'department',
            autofill: {
                name: 'employment_department_name',
            },
            width: 100
        },
    ]
};
