import React from 'react';
import PropTypes from 'prop-types';
import { INCIDENT_TYPE_WORKPLACE_VIOLENCE } from 'config';

import CreateIncident from './CreateIncident';

const CreateIncidentsOnEvent = ({ 
    disabled = false, 
}) => (
    <CreateIncident 
        fieldName="wpv_incident_involving_workplace_violence" 
        fieldValue="yes"
        incidentTypeId={INCIDENT_TYPE_WORKPLACE_VIOLENCE}
        disabled={disabled}
    />
);

CreateIncidentsOnEvent.propTypes = {
    disabled: PropTypes.bool,
};

export { CreateIncidentsOnEvent };
