import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AutoCompleteBase, Tag } from 'library';

const IntakeDropdown = ({
    value,
    onChange,
    onSelect,
    options,
    allowClear,
    children,
}) => {
    const { fields } = useSelector(state => state.incident);
    const [thisOptions, setThisOptions] = useState([]);

    const onBeforeChange = (val) => {
         if (onChange) {
            onChange((val?.target) ? val.target?.value : val);
        }
    };

    const onBeforeSelect = useCallback((val) => {
        if (onChange) {
            onChange(val);
        }
        const item = options.find(option => option.value === val);
        onSelect(item);
    }, [options, onChange, onSelect]);

    useEffect(() => {
        if (fields) {
            const res = options
                .filter(option => !!option?.value)
                .map(option => ({
                    value: option.value?.toString(),
                    label: (
                        <div>
                            {option.display ?? option.value}
                            {' '}
                            {option.label && <Tag>{option.label}</Tag>}
                        </div>
                    )
                }));

            setThisOptions(res);
            if (res.length) {
                onBeforeSelect(value ?? res[0].value);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields]);

    const input = React.cloneElement(children, { value, onChange: onBeforeChange });

    return (
        <AutoCompleteBase
            popupMatchSelectWidth={false}
            options={thisOptions}
            value={value}
            onSelect={onBeforeSelect}
            allowClear={allowClear}
        >
            {input}
        </AutoCompleteBase>
    );
};

IntakeDropdown.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    allowClear: PropTypes.bool
};

export { IntakeDropdown };
