import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Form, Popconfirm } from 'library';
import { useSelector } from 'react-redux';

const ButtonValidateIncident = ({
    loading = false,
    children, onClick,
    disabled = false,
    ...rest
}) => {
    const form = Form.useFormInstance();
    const [open, setOpen] = useState(false);
    const { usePyramid, ediUnique } = useSelector(state => state.location);

    const onBeforeClick = () => {
        form.validateFields()
            .then((values) => {
                if (usePyramid && ediUnique) {
                    const value = form.getFieldValue(['thirdParty', 'location']);
                    if (!value) {
                        setOpen(true);
                        return;
                    }
                }
                onClick(values);
            })
            .catch(Form.onFinishFailed);
    };

    return (
        <Popconfirm
            title="Gallagher Bassett Pyramid Location is not selected!"
            description=" Are you sure you want to continue?"
            onConfirm={(e) => {
                onClick(e);
                setOpen(false);
            }}
            onCancel={() => setOpen(false)}
            open={open}
        >
            <Button
                type="primary"
                className="lx-footer-btn"
                onClick={onBeforeClick}
                disabled={disabled}
                loading={loading}
                {...rest}
            >
                {children}
            </Button>
        </Popconfirm>
    );
};

ButtonValidateIncident.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export { ButtonValidateIncident };
