import PropTypes from 'prop-types';
import { FacilityTypeIcon } from 'config';
import { Tooltip, Badge, Tag } from 'library';
import { FacilityCard } from 'components/Facility';

const FacilitySelected = ({ facility, onClear }) => {
    const contactName = facility?.facilityProviderContactName ? ` - ${facility.facilityProviderContactName}` : '';
    let facilityName = facility?.name || '';
    facilityName = facilityName.lenght > 100 ? `${facilityName.slice(0, 20)}...` : facilityName;
    const facilityId = facility.id?.toString().split('-')[0];

    return (
        <Tooltip
            overlayClassName="facility-selected-popover"
            placement="top"
            title={facilityId ? <FacilityCard id={facilityId} /> : ''}
        >
            <Tag
                closable
                onClose={() => onClear(null)}
            >
                {!facility.id && <Badge status="processing" />}
                {FacilityTypeIcon[facility?.type]}
                <div>
                    {facilityName}
                    <span>{contactName}</span>
                </div>
            </Tag>
        </Tooltip>
    );
};

FacilitySelected.propTypes = {
    facility: PropTypes.object.isRequired,
    onClear: PropTypes.func.isRequired
};

export default FacilitySelected;
