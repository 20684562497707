import { USStateOptions } from 'config';

export default {
    source: 'GB Pyramid',
    sourceType: 'location',
    title: 'GB Location',
    className: 'info',
    columns: [
        {
            label: 'Title',
            name: 'name',
            filter: 'name',
            autofill: {
                name: 'employer_location_name',
                editable: true
            },
            width: 200,
        },
        {
            label: 'Address',
            name: 'address',
            filter: 'address',
            autofill: {
                name: 'employer_location_address',
                editable: true
            },
            width: 200,
        },
        {
            label: 'City',
            name: 'city',
            filter: 'city',
            autofill: {
                name: 'employer_location_city',
                editable: true
            },
            width: 150,
        },
        {
            label: 'State',
            name: 'stateAbbr',
            filter: 'state',
            autofill: {
                name: 'employer_location_state',
                editable: true
            },
            options: USStateOptions,
            width: 100,
        },
        {
            label: 'Zip',
            name: 'zip',
            filter: 'postalCode',
            autofill: {
                name: 'employer_location_zip',
                editable: true
            },
            width: 80,
        },
        {
            label: 'Reference',
            name: 'referenceNumber',
            filter: 'referenceNumber',
            autofill: {
                name: 'employer_location_number',
                editable: true
            },
            width: 80,
        }
    ]
};
