import dayjs from 'dayjs';
import { InputDate } from '../../InputDate';
 
const InputDateES = (props) => {
    const disabledDate = current => current && current > dayjs().add(1, 'day');
    return (
        <InputDate 
            disabledDate={disabledDate}
            {...props}
        />
    );
}; 

export { InputDateES };
