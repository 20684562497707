/* eslint-disable max-len */
export default {
    source: 'Psw API',
    sourceType: 'contact',
    title: 'Public School Works',
    transform: data => data.sortByString('firstName'),
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            required: true,
            width: 120,
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            filter: 'firstName',
            required: true,
            width: 120,
        },
        {
            label: 'Occupation',
            name: 'primaryOccupation',
            autofill: {
                name: 'employment_job_title_or_occupation',
            },
            width: 150
        },
        {
            label: 'Email',
            name: 'email',
            autofill: {
                name: 'claimant_email_address'
            },
            width: 120
        },
        {
            label: 'Mobile Phone',
            name: 'phoneNumber',
            autofill: {
                name: 'claimant_mobile_phone',
                editable: true
            },
            width: 150
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 100
        },
        {
            label: 'State',
            autofill: {
                name: 'claimant_state'
            },
            name: 'state',
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
        {
            label: 'Department',
            name: 'department',
            autofill: {
                name: 'employment_department_name',
            },
            width: 150
        },
        {
            label: 'District',
            name: 'district',
            width: 150
        },
    ]
};
