/* eslint-disable max-len */
import { formatDate, formatDateOut } from 'utils';

export default {
    source: 'Safelite',
    sourceType: 'contact',
    title: 'Safelite',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [

        {
            fixed: true,
            label: 'Last Name',
            autofill: {
                name: 'claimant_last_name'
            },
            name: 'lastName',
            filter: 'lastName',
            width: 120
        },
        {
            label: 'First Name',
            autofill: {
                name: 'claimant_first_name'
            },
            name: 'firstName',
            filter: 'firstName',
            required: true,
            width: 100,
        },
        {
            label: 'SSN (last 4 digits)',
            autofill: {
                name: 'claimant_ssn'
            },
            name: 'ssn',
            filter: 'ssn',
            width: 130
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            filter: 'dob',
            width: 120
        },
        {
            label: 'Employee Id',
            name: 'employeeId',
            autofill: {
                name: 'claimant_employee_id'
            },
            width: 150
        },
        {
            label: 'Occupation',
            name: 'jobTitle',
            autofill: {
                name: 'employment_job_title_or_occupation',
            },
            width: 100
        },
        {
            label: 'Hire Date',
            name: 'hireDate',
            autofill: {
                name: 'employment_hired_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Email',
            autofill: {
                name: 'claimant_email_address'
            },
            name: 'email',
            width: 100
        },
        {
            label: 'Home Phone',
            name: 'homePhone',
            autofill: {
                name: 'claimant_mobile_phone',
                editable: true
            },
            width: 90
        },
        {
            label: 'Marital Status',
            name: 'maritalStatus',
            autofill: {
                name: 'claimant_marital_status_code',
                transform: value => (
                    {
                        'Single (United States of America)': '2',
                        'Married (United States of America)': '4',
                        'Divorced (United States of America)': '3',
                        'Separated (United States of America)': '5',
                        'Not Indicated (United States of America)': '7',
                        'Civil Partnership (United States of America)': '8',
                    }[value] || undefined)
            },
            width: 100
        },
        {
            label: 'Address',
            name: 'homeAddress',
            autofill: {
                name: 'claimant_address'
            },
            width: 120
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 90
        },
        {
            label: 'State',
            name: 'state',
            autofill: {
                name: 'claimant_state'
            },
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
        {
            label: 'Supervisor First Name',
            name: 'supervisorFirstName',
            autofill: {
                name:
                    'employment_supervisor_first_name'
            },
            width: 100
        },
        {
            label: 'Supervisor Last Name',
            name: 'supervisorLastName',
            autofill: {
                name:
                    'employment_supervisor_last_name'
            },
            width: 100
        },
        {
            label: 'Supervisor Phone Number',
            name: 'supervisorPhone',
            autofill: {
                name:
                    'employment_supervisor_phone'
            },
            width: 100
        },
    ]
};

/*
    [
 {
            "firstName": "John",
            "lastName": "Griffin",
            "email": "",
            "ssn": "7430",
            "dob": "1982-05-09",
            "employeeId": "00006715",
            "hireDate": "07\/01\/2008",
            "homeAddress": "23 Tener St",
            "homePhone": "",
            "maritalStatus": "Single (United States of America)",
            "jobTitle": "W8203 - Warehouse Associate I",
            "supervisorFirstName": "Daniel",
            "supervisorLastName": "Malcomb",
            "supervisorPhone": "",
            "city": "Luzerne",
            "state": "PA",
            "zip": "18709",
            "primaryOccupation": null
        }
    ]
*/
