import './Fieldset.scss';
import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { CloseCircleFilled } from '@ant-design/icons';
import { getIntakeLookup } from 'config/lookups';
import { Form } from '../Form';
import { FormItemExtended } from '../FormItemExtended';
import { FormItemDirective } from '../FormItemDirective';

const Fieldset = ({
    id, title, fields = [], children, directives = [],
    allowClear, onChange, namePrefix,
    disabled = false
}) => {
    const form = Form.useFormInstance();
    const fieldsetId = useId();

    const onClearClick = () => {
        const emptyValues = Object.fromEntries(fields.map(item => ([item.name, undefined])));
        form.setFieldsValue({ fields: emptyValues });
        if (onChange) {
            onChange({ fields: emptyValues });
        }
    };
    
    const topDirectives = directives?.filter(({ placement }) => !placement?.bottom);
    const bottomDirectives = directives?.filter(({ placement }) => !!placement?.bottom);

    return (
        <fieldset>
            {(title || allowClear) && (
                <div className="form-category-header">
                    <span className="title">
                        <span
                            className="scroll-to-fieldset"
                            id={id || fieldsetId}
                        />
                        {title}
                    </span>
                    {allowClear && !disabled && (
                        <CloseCircleFilled
                            onClick={onClearClick}
                            title={`Clear ${title} data`}
                        />
                    )}
                </div>
            )}
            {topDirectives.map(item => (
                <FormItemDirective
                    key={item.id}
                    disabled={disabled}
                    {...item}
                />
            ))}
            {fields.map(({ categoryId, name, disabled: itemDisabled, ...rest }) => {
                const lookup = getIntakeLookup(`question$${name}`, disabled);

                return (
                    <React.Fragment key={name}>
                        {lookup}
                        <FormItemExtended
                            name={name}
                            prefix={namePrefix}
                            disabled={itemDisabled || disabled}
                            {...rest}
                        />
                    </React.Fragment>
                    
                );
            })}
            {bottomDirectives.map(item => (
                <FormItemDirective
                    key={item.id}
                    disabled={disabled}
                    {...item}
                />
            ))}
            {children}
        </fieldset>
    );
};

Fieldset.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    fields: PropTypes.array,
    allowClear: PropTypes.bool,
    onChange: PropTypes.func,
    namePrefix: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element
    ]),
    disabled: PropTypes.bool,
    directives: PropTypes.array
};

export { Fieldset };
