import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Form, Button } from 'library';
import { randomTo } from 'utils';
import { QUESTION_TYPE } from 'config';
import { BgColorsOutlined } from '@ant-design/icons';
import { fakerEN_US as faker } from '@faker-js/faker';

// https://fakerjs.dev/api/date.html#birthdate

const AutoFillButton = ({ categories = [] }) => {
    const form = Form.useFormInstance();

    const generateValue = ({ type, name, options }) => {
        if (name.endsWith('_first_name')) {
            return faker.person.firstName();
        }
        if (name.endsWith('_last_name')) {
            return faker.person.lastName();
        }
        if (name.endsWith('_occupation')) {
            return faker.person.jobTitle();
        }
        if (name.endsWith('_address2')) {
            return faker.location.secondaryAddress();
        }
        if (name.endsWith('_city')) {
            return faker.location.city();
        }
        if (name.endsWith('_zip')) {
            return faker.location.zipCode();
        }
        if (name.endsWith('_phone') || name?.endsWith('_fax')) {
            return `+1555${ faker.number.int({ min: 1111111, max: 1111111 * 9 })}`; 
        }
        
        if (name.endsWith('_ssn')) {
            return '5433';
        }

        if (name.endsWith('_email_address')) {
            return faker.internet.email();
        } if (name.endsWith('_address')) {
            return faker.location.streetAddress();
        }
        if (name === 'employment_hired_date') {
            return dayjs(faker.date.past({ years: 5, refDate: '2020-01-01' })).format('YYYY-MM-DD');
        }
        if (name === 'claimant_birth_date') {
            return dayjs(faker.date.birthdate()).format('YYYY-MM-DD');
        }
        if (name === 'injury_date') {
            return dayjs(faker.date.recent({ days: 3 })).format('YYYY-MM-DD');
        }
        
        if ([QUESTION_TYPE.LOOKUP, QUESTION_TYPE.SELECT].includes(type) && Array.isArray(options)) {
            const idx = randomTo(options.length - 1);
            return options[idx].value;
        }
        switch (type) {
            case QUESTION_TYPE.YESNO:
                return faker.helpers.arrayElement(['1', '2']); 
            case QUESTION_TYPE.CHECKBOX:
                return faker.helpers.arrayElement([true, false]);
            case QUESTION_TYPE.CHECKBOXES:
                return faker.helpers.arrayElement(options.map(item => item.value));
            case QUESTION_TYPE.DATE:
                return dayjs(faker.date.past({ years: 1 })).format('YYYY-MM-DD');
            case QUESTION_TYPE.TIME:
                return dayjs(faker.date.anytime()).format('HH:mm');
            case QUESTION_TYPE.DATETIME:
                return null;
            case QUESTION_TYPE.TEXTAREA:
                return faker.lorem.sentence();
            default:
                return faker.word.sample(); 
        }
    };

    const generateFieldValue = (question) => {
        const initial = { value: undefined, refused: false, unknown: false };

        if (question.required) {
            const pos = randomTo(3);
            initial.refused = pos === 2;
            initial.unknown = pos === 3;
        }

        if (!initial.refused && !initial.unknown) {
            initial.value = generateValue(question);
        }
        return initial;
    };

    const onClick = () => {
        const questions = categories.reduce((acc, value) =>
            [...acc, ...value.questions], []);

        const fields = {};
        questions.forEach((item) => {
            fields[item.name] = generateFieldValue(item);
        });
        form.setFieldsValue({ fields });
    };

    return (
        <Button
            onClick={onClick}
            title="Fill with Random Data (for testing only)"
            disabled={!categories?.length}
            ghost
            type="primary"
            icon={<BgColorsOutlined />}
        />
    );
};

AutoFillButton.propTypes = {
    categories: PropTypes.array
};

export default AutoFillButton;
