import './FormItemExtended.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'library';
import { Rules } from 'utils';
import FormField from './Field';
import { calculateShowIf, generateRules } from './helpers';
import ButtonCopy from './ButtonCopy';

const FormItemExtended = ({
    required = false,
    estimated = false,
    disabled = false,
    type,
    name, 
    prefix, label, 
    showIf, copy, 
    ...rest
}) => {
    const [autofilled, setAutofilled] = useState(false);
    const rules = generateRules(type, rest.rules);
    if (required) {
        rules.push(Rules.requiredExt);
    }
    if (estimated) {
        rules.push(Rules.estimated);
    }
    
    if (type === 'DATE') {
        rules.push(Rules.date);
        rules.push(Rules.dateInPast);
    }
    
    const classNames = [
        `form-item-${prefix}-${name}`,
        'form-item',
        `form-item-${type}`,
        required ? 'required' : '',
        showIf ? 'show-if' : '',
    ].join(' ');

    const fullName = prefix ? [prefix, name] : name;

    const FullField = (
        <>
            <Form.Item
                className={classNames}
                name={fullName}
                label={label}
                rules={rules}
                hasFeedback={autofilled}
                validateStatus={autofilled ? 'success' : undefined}
            >
                <FormField
                    required={required}
                    type={type}
                    disabled={disabled}
                    estimated={estimated}
                    name={name}
                    showIf={showIf}
                    setAutofilled={setAutofilled}
                    {...rest}
                />
            </Form.Item>
            {copy && (
                <ButtonCopy
                    config={copy}
                    disabled={disabled}
                />
            )}
        </>
    );

    if (showIf) {
        return (
            <Form.Item
                noStyle
                shouldUpdate
            >
                {({ getFieldValue }) => {
                    const visible = calculateShowIf(showIf, getFieldValue);
                    return visible ? FullField : null;
                }}
            </Form.Item>
        );
    }

    return FullField;
};

FormItemExtended.propTypes = {
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    estimated: PropTypes.bool,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    showIf: PropTypes.object, // @TODONEXT shape it
    copy: PropTypes.object, // @TODONEXT shape it
    prefix: PropTypes.string
};

export { FormItemExtended };
