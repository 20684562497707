import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Popover, Form } from 'library';
import { EsLocationsService } from 'services';
import { ImportOutlined, LoadingOutlined } from '@ant-design/icons';
import { setLocation, clear as clearLocation } from 'store/location';
import IncidentContext from 'routes/Incident/context';
import config from './config';
import { LookupDropdown } from '../Dropdown';

const LookupLocationField = ({
    value, onChange,
    loading = false,
    disabled = false,
    defaultFilters
}) => {
    const form = Form.useFormInstance();
    const { update } = useContext(IncidentContext);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const location = useSelector(state => state.location);
    const { incidentTypeId: callIncidentTypeId } = useSelector(state => state.call);

    // https://objectsystems.atlassian.net/browse/LGB-252
    const calcIncidentTypeId = (episodeTypes) => {
        let res = null;
        const { episodeTypeId: current } = form.getFieldsValue();
        if (episodeTypes.length === 1) {
            res = episodeTypes[0].id;
        } else if (episodeTypes.find(it => it.id === current)) {
            res = current;
        } else if (episodeTypes.find(it => it.id === callIncidentTypeId)) {
            res = callIncidentTypeId;
        }
        return res;
    };

    const onOk = async (item) => {
        const newLocation = item.thirdParty;
        const { id: locationId, episodeTypeId: episodeTypes } = newLocation;

        const episodeTypeId = calcIncidentTypeId(episodeTypes);
        if (update && episodeTypeId) {
            return update({ locationId, episodeTypeId });
        }
        dispatch(setLocation(newLocation));
        form.setFieldsValue({ episodeTypeId });
        onChange(locationId);
        return Promise.resolve();
    };

    useEffect(() => {
        if (!value) {
            dispatch(clearLocation());
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, value]);

    const service = args => EsLocationsService.list({
        ...args,
        reportableLocation: 1,
        page: 1,
        perPage: 1000,
    });

    return (
        <Popover
            content={(
                <LookupDropdown
                    onCancel={() => setOpen(false)}
                    onOk={onOk}
                    setOpen={setOpen}
                    service={service}
                    defaultFilters={defaultFilters}
                    {...config}
                />
            )}
            placement="bottomRight"
            open={!disabled && open}
            overlayClassName={`lookup-popover ${config.className}`}
            getPopupContainer={trigger => trigger.parentNode.parentNode}
        >
            <Input
                className="cursorPointer"
                onClick={() => setOpen(true)}
                readOnly
                suffix={(loading || location.$loading)
                    ? <LoadingOutlined style={{ color: 'gray' }} />
                    : <ImportOutlined onClick={() => setOpen(!open)} />}
                value={loading ? '' : location?.title}
                disabled={disabled}
                placeholder="Required"
            />
        </Popover>
    );
};

LookupLocationField.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultFilters: PropTypes.object.isRequired,
};

export default LookupLocationField;
