import './SendToTriage.scss';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form, Tag, InputPhone } from 'library';
import { IntakeDropdown } from 'components/IntakeDropdown';
import { useSelector } from 'react-redux';
import { Rules, formatPhone } from 'utils';

const FormItemCallbackPhone = ({ required }) => {
    const { fields } = useSelector(state => state.incident);
    const [phoneDesc, setPhoneDesc] = useState();

    return (
        <Form.Item
            name="callbackPhone"
            label={(
                <>
                    Phone
                    {phoneDesc && (
                        <Tag bordered>
                            {phoneDesc}
                        </Tag>
                    )}
                </>
            )}
            rules={required ? [Rules.required] : []}
        >
            <IntakeDropdown
                onSelect={item => setPhoneDesc(item?.label)}
                renderLabel={formatPhone}
                allowClear={false}
                options={[
                    {
                        label: 'Claimant Mobile Phone',
                        value: fields.claimant_mobile_phone?.value,
                        display: formatPhone(fields.claimant_mobile_phone?.value)

                    },
                    {
                        label: 'Claimant Work Phone',
                        value: fields.claimant_work_phone?.value,
                        display: formatPhone(fields.claimant_work_phone?.value)
                    },
                    {
                        label: 'Claimant Home Phone',
                        value: fields.claimant_home_phone?.value,
                        display: formatPhone(fields.claimant_home_phone?.value)
                    },
                    {
                        label: 'Caller Phone',
                        value: fields.caller_phone?.value,
                        display: formatPhone(fields.caller_phone?.value)
                    },
                ]}
            >
                <InputPhone 
                    returnsEvent 
                />
            </IntakeDropdown>
        </Form.Item>
    );
};

FormItemCallbackPhone.propTypes = {
    required: PropTypes.bool.isRequired,
};

export { FormItemCallbackPhone };
