import './Form.scss';
import { Form as FormBase } from 'antd';
import scrollIntoView from 'scroll-into-view-if-needed';

const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
        const [field] = errorFields;
        const className = `form-item-${Array.isArray(field.name) ? field.name.join('-') : field.name}`;
        const el = document.querySelector(`.${className}`);
        if (el) {
            scrollIntoView(el, { block: 'center' });
        }
    }
};

const Form = ({ ...rest }) => (
    <FormBase
        onFinishFailed={onFinishFailed}
        {...rest}
    />
);

Form.useFormInstance = FormBase.useFormInstance;
Form.useForm = FormBase.useForm;
Form.Item = FormBase.Item;
Form.List = FormBase.List;
Form.Provider = FormBase.Provider;
Form.onFinishFailed = onFinishFailed;
Form.useWatch = FormBase.useWatch;

export { Form };
