import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useBackDisabled = () => {
    const navigate = useNavigate();
    
    const onBack = useCallback((event) => {
        event.preventDefault();
        navigate(1);
    }, [navigate]);

    useEffect(() => {
        window.history.pushState(null, null, document.URL);
        window.addEventListener('popstate', onBack);
        return () => {
            window.removeEventListener('popstate', onBack);
        };
    }, [onBack]);
};

export { useBackDisabled };
