import { USStateOptions } from 'config/states';
import { DISTRIBUTION_MODE, DISTRIBUTION_MODE_MAP } from 'config/location';

export default {
    source: 'Enterprise',
    sourceType: 'location',
    title: 'Injury Location',
    className: 'w1000 info',
    areFiltersReady: filters => Object.keys(filters)
        .filter(key => key !== 'distributionMode').length > 0,
    isDisabled: row => row.distributionMode === DISTRIBUTION_MODE.OFF,
    columns: [
        {
            label: 'Mode',
            name: 'distributionMode',
            filter: 'distributionMode',
            options: [
                { label: 'Active', value: DISTRIBUTION_MODE.LIVE },
                { label: 'Off', value: DISTRIBUTION_MODE.OFF },
                { label: 'All', value: ' ' },
            ],
            render: value => DISTRIBUTION_MODE_MAP[value],
            width: 70,
        },
        {
            label: 'Title',
            name: 'title',
            filter: 'titleElastic', 
            width: 220,
        },
        {
            label: 'Address',
            name: 'address1',
            filter: 'address1',
            autofill: {
                name: 'injury_address',
            },
            width: 180,
        },
        {
            label: 'Address 2',
            name: 'address2',
            filter: 'address2',
            autofill: {
                name: 'injury_address2',
            },
            width: 180,
        },
        {
            label: 'City',
            name: 'city',
            filter: 'city',
            autofill: {
                name: 'injury_city',
            },
            width: 120,
        },
        {
            label: 'State',
            name: 'state',
            filter: 'state',
            options: USStateOptions,
            autofill: {
                name: 'injury_state',
            },
            width: 80,
        },
        {
            label: 'Zip',
            name: 'zip',
            filter: 'zip',
            autofill: {
                name: 'injury_zip',
            },
            width: 80,
        },
    ]
};
