import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Form, notification } from 'library';
import { INCIDENT_STATE } from 'config';
import { ButtonValidateIncident } from 'components/buttons';
import IncidentContext from 'routes/Incident/context';
import SendToTriageModal from './Modal';

const SendToTriageButton = ({ disabled }) => {
    const { update } = useContext(IncidentContext);
    const navigate = useNavigate();
    const { isIntakeOnly } = useSelector(state => state.location);
    const incident = useSelector(state => state.incident);
    const { $updating } = incident;
    const form = Form.useFormInstance();
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [sending, setSending] = useState(false);

    const onTriageClick = () => {
        setOpenMenu(false);
        setOpen(true);
    };

    const onNoTriageClick = () => {
        setOpenMenu(false);
        setSending(INCIDENT_STATE.NO_TRIAGE);
        form.validateFields()
            .then(values => update({
                id: incident.id,
                ...values,
                incidentState: INCIDENT_STATE.NO_TRIAGE
            }))
            .then(() => {
                notification.success({ message: 'Saved Successfully' });
                navigate(`/incidents/${incident.id}/no-triage`);
            })
            .catch(Form.onFinishFailed)
            .finally(() => setSending(false));
    };

    const onContinueClick = () => setOpenMenu(true);

    return (
        <>
            <SendToTriageModal
                open={open}
                setOpen={setOpen}
            />
            <Dropdown
                menu={{
                    items: [
                        {
                            key: INCIDENT_STATE.TRIAGE,
                            label: 'Send Call & Report to Nurse ... ',
                            danger: true,
                            onClick: onTriageClick,
                            disabled: isIntakeOnly,
                            title: isIntakeOnly ? 'This is a Report Only Client' : 'Nurse Required'
                        },
                        {
                            key: INCIDENT_STATE.NO_TRIAGE,
                            label: 'No Nurse Required',
                            onClick: onNoTriageClick,
                        },
                    ]
                }}
                placement="topLeft"
                disabled={$updating || disabled}
                trigger={[]}
                open={openMenu}
                autoFocus
            >
                <div>
                    <ButtonValidateIncident
                        onClick={onContinueClick}
                        disabled={disabled}
                        loading={!!sending}
                    >
                        Continue ...
                    </ButtonValidateIncident>
                </div>
            </Dropdown>
        </>
    );
};

SendToTriageButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
};

export default SendToTriageButton;
