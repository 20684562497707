/* Uses E.164 is an international standard (ITU-T Recommendation), 
titled The international public telecommunication numbering plan */

import './InputPhone.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneE164 } from 'utils';
import PhoneInputWithCountry from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

const InputPhoneWithCountry = props => (
    <PhoneInputWithCountry
        defaultCountry="US"
        initialValueFormat="national"
        {...props}
    />
);

const InputPhone = React.forwardRef(({ value, onChange, returnsEvent, onPressEnter, className = '', ...rest }, ref) => {
    const thisValue = formatPhoneE164(value);

    const onKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            onPressEnter();
        }
    };

    const onBeforeChange = (val) => {
        if (returnsEvent) {
            onChange({ target: { value: val } });
        } else {
            onChange(val || '');
        }
    };

    return (
        <PhoneInput
            ref={ref}
            className={`input-phone ${className}`}
            country="US"
            value={thisValue}
            onKeyDown={onPressEnter && onKeyDown}
            onChange={onBeforeChange}
            {...rest}
        />
    );
});

InputPhone.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPressEnter: PropTypes.func,
    className: PropTypes.string,
    onChange: PropTypes.func,
    returnsEvent: PropTypes.bool
};

export { InputPhoneWithCountry, InputPhone };
