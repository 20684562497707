import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FloatButton } from 'library';
import { useParams } from 'react-router-dom';
import { ButtonCallActions } from 'components/buttons';
import { IncidentDuplicatesIntake } from 'components/IncidentDuplicates';
import { isProd } from 'config';
import VoidButton from './VoidButton';
import SendToTriageButton from './SendToTriageButton';
import ReportOnlyButton from './ReportOnlyButton';
import NextIntakeButton from './NextIntakeButton';
import AutoDispatchButton from './AutoDispatchButton';
import AutoFillButton from './AutoFillButton';

const IntakeEditFooter = ({ disabled, categories }) => {
    const { id, submitted, $isReportOnly } = useSelector(state => state.incident);
    const { nextId } = useParams();

    return (
        <footer>
            {id && !submitted && (
                <IncidentDuplicatesIntake />
            )}
            <FloatButton.BackTop />
            <ButtonCallActions />
            {!isProd && <AutoDispatchButton /> }
            {!isProd && <AutoFillButton categories={categories} />}
            <VoidButton />
            {nextId && (
                <NextIntakeButton
                    nextId={nextId}
                    disabled={disabled}
                />
            )}
            {!nextId && $isReportOnly && (
                <ReportOnlyButton 
                    disabled={disabled}
                />
            )}
            {!nextId && !$isReportOnly && (
                <SendToTriageButton 
                    disabled={disabled} 
                />
            )}
        </footer>
    );
};

IntakeEditFooter.propTypes = {
    disabled: PropTypes.bool.isRequired,
    categories: PropTypes.array
};

export default IntakeEditFooter;
