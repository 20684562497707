import './ButtonEstimated.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';

const ButtonEstimated = ({ value, onChange, disabled }) => (
    <Tooltip title="Estimate">
        <Button
            onClick={() => onChange({ value: value?.value, estimated: !value?.estimated })}
            className={`btn-alt-value btn-est ${value?.estimated ? 'selected' : ''}`}
            icon={<FieldTimeOutlined />}
            disabled={disabled}
            tabIndex={-1} 
        />
    </Tooltip>
);

ButtonEstimated.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool.isRequired
};

export default ButtonEstimated;
