import dayjs from 'dayjs';

export const RULES = {
    claimant_birth_date: [
        { operator: 'less', fieldName: 'employment_hired_date', message: 'Must be before Hire Date' },
        { operator: 'less', fieldName: 'injury_date', message: 'Must be before Date of Injury' },
        { operator: 'less', fieldName: 'injury_employer_notified_date', message: 'Must be before Date Supervisor Notified' }
    ],
    employment_hired_date: [
        { operator: 'lessOrEqual', fieldName: 'injury_date', message: 'Must be on or before Date of Injury' },
        { operator: 'lessOrEqual', fieldName: 'injury_employer_notified_date', message: 'Must be on or before Date Supervisor Notified' },
        { operator: 'greater', fieldName: 'claimant_birth_date', message: 'Must be after Date of Birth' },
    ],
    injury_date: [
        { operator: 'lessOrEqual', fieldName: 'injury_employer_notified_date', message: 'Must be on or before Date Supervisor Notified' },
        { operator: 'greaterOrEqual', fieldName: 'employment_hired_date', message: 'Must be on or after Hire Date' },
        { operator: 'greater', fieldName: 'claimant_birth_date', message: 'Must be after Date of Birth' },
    ],
    injury_employer_notified_date: [
        { operator: 'greaterOrEqual', fieldName: 'injury_date', message: 'Must be on or after Date of Injury' },
        { operator: 'greaterOrEqual', fieldName: 'employment_hired_date', message: 'Must be on or after Hire Date' },
        { operator: 'greater', fieldName: 'claimant_birth_date', message: 'Must be after Date of Birth' },
    ],
    injury_employer_notified_time: [
        {
            operator: ({ getFieldValue, value }) => {
                const thisTime = value?.value;
                let otherDate = getFieldValue(['fields', 'injury_date'])?.value;
                let thisDate = getFieldValue(['fields', 'injury_employer_notified_date'])?.value;
                const otherTime = getFieldValue(['fields', 'injury_time'])?.value;
                if (thisDate && otherDate && thisTime && otherTime) {
                    thisDate = dayjs(`${thisDate } ${ thisTime}`);
                    otherDate = dayjs(`${otherDate } ${ otherTime}`);
                    if (thisDate < otherDate) {
                        return Promise.reject(Error('Must be after Time of Injury'));
                    }
                }
                return Promise.resolve();
            }
        }
    ]
};
