import dayjs from 'dayjs';

export const formats = {
    date: {
        in: 'MM/DD/YYYY',
        out: 'YYYY-MM-DD'
    },
    time: {
        in: 'h:mm a',
        out: 'HH:mm',
    },
    dateTime: {
        in: 'MM/DD/YYYY h:mm A',
        out: 'YYYY-MM-DD HH:mm',
    },
    dateTimeSec: {
        in: 'MM/DD/YYYY h:mm:ss A',
        out: 'YYYY-MM-DD HH:mm:ss',
    }
};

export const formatPhone = (value) => {
    if (!value) {
        return value;
    }
    let cleaned = value.toString();
    if (cleaned.startsWith('+1')) {
        cleaned = cleaned.substring(2);
    }
    cleaned = cleaned.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return value;
};

export const formatPhoneE164 = (value, code = '+1') => {
    if (!value) {
        return value;
    }
    let temp = value.toString();
    if (!temp.startsWith(code)) {
        temp = `${code}${temp}`;
    }
    return temp.replace(/[- ()*]/g, '');
};

export const format = (value, pattern) => (value ? dayjs(value).format(pattern) : '');

export const formatDate = value => format(value, formats.date.in);

export const formatDateTime = value => format(value, formats.dateTime.in);

export const formatDateOut = value => format(value, formats.date.out);

export const formatSsn = (ssn) => { 
    if (!ssn) {
        return '';
    }
    const input = ssn.replace(/\D/g, '');
    if (input.length > 5) {
        return `••• •• ${ ssn.substr(5, 11)}`;
    }
    return input;
};
