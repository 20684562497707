import React from 'react';
import PropTypes from 'prop-types';
import { Form, Fieldset } from 'library';
import { getIntakeLookup } from 'config/lookups';

const IntakeFieldset = ({ readOnly, category, directives, onValuesChange }) => {
    const [form] = Form.useForm();

    const onFieldsetChange = () => {
        const all = form.getFieldsValue(true);
        onValuesChange({}, all);
    };

    const catDirectives = directives?.filter(item => item.placement?.section === category.name);
    return (
        <React.Fragment key={category.id}>
            {getIntakeLookup(`category$${ category.name}`, readOnly)}
            <Fieldset
                id={category.name}
                title={category.title}
                namePrefix="fields"
                fields={category.questions}
                directives={catDirectives}
                allowClear
                onChange={onFieldsetChange}
                disabled={readOnly}
            />
        </React.Fragment>
    );
};

IntakeFieldset.propTypes = {
    directives: PropTypes.array,
    category: PropTypes.object.isRequired,
    onValuesChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default IntakeFieldset;
