import React from 'react';
import { Form } from 'library';
import LocationInput from './LocationInput';

const LookupInjuryLocation = props => (
    <Form.Item
        label="Injury Location"
    >
        <LocationInput {...props} />
    </Form.Item>
);

export { LookupInjuryLocation };
