import { formatPhone } from 'utils';
import { USStateOptions } from 'config/states';
import { DISTRIBUTION_MODE, DISTRIBUTION_MODE_MAP } from 'config/location';
import { Help } from 'library';

export default {
    source: 'Enterprise',
    sourceType: 'location',
    title: 'Employer Location',
    className: 'w1000 info',
    areFiltersReady: filters => Object.keys(filters)
        .filter(key => key !== 'distributionMode').length > 0,
    isDisabled: row => row.distributionMode === DISTRIBUTION_MODE.OFF,
    columns: [
        {
            label: 'Mode',
            name: 'distributionMode',
            filter: 'distributionMode',
            options: [
                { label: 'Active', value: DISTRIBUTION_MODE.LIVE },
                { label: 'Off', value: DISTRIBUTION_MODE.OFF },
                { label: 'All', value: ' ' },
            ],
            render: value => DISTRIBUTION_MODE_MAP[value],
            width: 70,
        },
        {
            label: 'Title',
            name: 'title',
            filter: 'titleElastic', 
            width: 220,
            render: (value, row) => {
                const { distributionMode, thirdParty: { offModeMessage } } = row;
                if (distributionMode === DISTRIBUTION_MODE.OFF && offModeMessage) {
                    return (
                        <Help tooltip={offModeMessage}>
                            {value}
                        </Help>
                    );
                }
                return value;
            }
        },
        /*
        {
            label: 'Title (Full Text)',
            name: 'titleFullText',
            filter: 'titleFullText',
            width: 220,
            render: (_, row) => row.title
        }, */
        {
            label: 'Search Code',
            name: 'clientCode',
            filter: 'clientCode',
            width: 80,
        },
        {
            label: 'Address',
            name: 'address1',
            filter: 'address1',
            width: 180,
        },
        {
            label: 'City',
            name: 'city',
            filter: 'city',
            width: 120,
        },
        {
            label: 'State',
            name: 'state',
            filter: 'state',
            options: USStateOptions,
            width: 80,
        },
        {
            label: 'Zip',
            name: 'zip',
            filter: 'zip',
            width: 80,
        },
        {
            label: 'Assigned Phone Number',
            name: 'assignedPhoneNumber', 
            filter: 'assignedPhoneNumber',
            filterType: 'phone',
            render: formatPhone,
            width: 120,
        },
    ]
};
