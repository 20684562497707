import './Header.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'library';
import { DatabaseFilled, PlusOutlined } from '@ant-design/icons';
import { NoteLibraryDropdown } from '../LibraryDropdown';

const NotesHeader = ({ onChange, libraryItems, updating, disabled }) => {
    const [adding, setAdding] = useState(false);
    const focusFirst = () => {
        setTimeout(() => {
            const textarea = document.querySelector('.notes .note textarea');
            if (textarea) {
                textarea.focus();
                textarea.selectionStart = textarea.value.length;
            }
        });
    };

    const addNote = (text) => {
        setAdding(true);
        onChange({ text }, true)
            .then(focusFirst)
            .finally(() => setAdding(false));
    };

    return (
        <h2 className="hotes-header">
            Notes
            {!disabled && (
                <>
                    <NoteLibraryDropdown
                        items={libraryItems}
                        onSelect={addNote}
                        trigger="click"
                    >
                        <DatabaseFilled
                            title="Library"
                        />
                    </NoteLibraryDropdown>
                    <Button
                        type="primary"
                        shape="circle"
                        size="small"
                        className="btn-add-note"
                        icon={<PlusOutlined />}
                        title="Add New Note"
                        onClick={() => addNote('')}
                        disabled={updating}
                        loading={adding}
                    />
                </>
            )}
        </h2>
    );
};

NotesHeader.propTypes = {
    onChange: PropTypes.func.isRequired,
    libraryItems: PropTypes.array.isRequired,
    updating: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default NotesHeader;
