/* eslint-disable max-len */
import { formatSsn, formatDateOut, formatDate } from 'utils';

export default {
    source: 'TriStar',
    sourceType: 'contact',
    title: 'TriStar Contacts',
    className: 'w1000 warning',
    description: <>This employer <b>requires</b> you to search and select your claimant from the employer&apos;s database directly!</>,
    columns: [
        {
            fixed: true,
            label: 'Last Name',
            name: 'lastName',
            filter: 'lastName',
            required: true,
            autofill: {
                name: 'claimant_last_name'
            },
            width: 120,
        },
        {
            label: 'First Name',
            name: 'firstName',
            filter: 'firstName',
            required: true,
            autofill: {
                name: 'claimant_first_name'
            },
            width: 120,
        },
        {
            label: 'Middle Name',
            name: 'middleName',
            autofill: {
                name: 'claimant_middle_initial'
            },
            width: 120,
        },
        {
            label: 'SSN',
            name: 'ssn',
            filterType: 'ssn',
            render: formatSsn,
            filter: 'ssn',
            autofill: {
                name: 'claimant_ssn'
            },
            width: 120
        },
        {
            label: 'Date of Birth',
            name: 'dob',
            filter: 'dob',
            autofill: {
                name: 'claimant_birth_date',
                transform: formatDateOut
            },
            render: formatDate,
            width: 90
        },
        {
            label: 'Employee Id',
            name: 'employeeId',
            filter: 'employeeId',
            autofill: {
                name: 'claimant_employee_id'
            },
            width: 80
        },
        {
            label: 'Hire Date',
            name: 'hireDate',
            filter: 'hireDate',
            autofill: {
                name: 'employment_hired_date',
                transform: formatDateOut
            },
            width: 70
        },
        {
            label: 'Gender',
            name: 'gender',
            autofill: {
                name: 'claimant_gender_code',
                transform: value => (
                    {
                        M: '1',
                        F: '2'
                    }[value] || undefined)
            },
            width: 70,
        },
        {
            label: 'Address',
            name: 'address1',
            autofill: {
                name: 'claimant_address'
            },
            width: 220
        },
        {
            label: 'Address 2',
            name: 'address2',
            autofill: {
                name: 'claimant_address2'
            },
            width: 220
        },
        {
            label: 'City',
            name: 'city',
            autofill: {
                name: 'claimant_city'
            },
            width: 100
        },
        {
            label: 'State',
            autofill: {
                name: 'claimant_state'
            },
            name: 'state',
            width: 90
        },
        {
            label: 'Zip',
            name: 'zip',
            autofill: {
                name: 'claimant_zip'
            },
            width: 70
        },
    ]
};
